<template>
  <div></div>
</template>

<script>
import Vue from 'vue'
import { Toast } from 'vant'
Vue.use(Toast)

export default {
  data () {
    return {
    }
  },
  created () {
    const jsonStr = this.$route.query.data
    // 1 单点登录
    const SSO = this.$route.query.SSO
    if (jsonStr) {
      const { usdrerID, usdrerToken } = JSON.parse(jsonStr)
      const data = {
        usdrerID,
        usdrerToken
      }
      this.$store.commit('recordInfo', {
        type: 'userInfo',
        value: data
      })
      localStorage.setItem('userInfo', JSON.stringify(data))
      if (SSO) {
        sessionStorage.setItem('SSO', SSO)
      }
      this.$router.push('/home')
    } else {
      Toast(`错误信息：【${jsonStr}】`)
    }
  },
  methods: {
  }
}
</script>

<style scoped lang='less'>
</style>
